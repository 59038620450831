<template>
  <div>
    <Button
      icon="ph ph-magnifying-glass"
      rounded
      outlined
      aria-label="Ricerca globale"
      class="ml-2 text-2xl"
      @click="visible = !visible"
    />
    <Dialog
      v-model:visible="visible"
      modal
      position="top"
      maximizable
      :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <template #header>
        <IconField iconPosition="left" class="w-full">
          <InputIcon class="ph ph-magnifying-glass"> </InputIcon>
          <InputText
            placeholder="Ricerca nei progetti, nelle attività e dei documenti"
            class="w-full text-2xl"
            autofocus
            v-model.lazy="searchText"
            @keydown.enter="onSubmit"
          />
        </IconField>
      </template>
      <template #footer>
        <Button label="Chiudi" text oulined @click="visible = false" />
      </template>

      <BlockUI :blocked="blocked">
        <SearchResults :results="results" />
      </BlockUI>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import SearchResults from "./SearchResults.vue";
import BlockUI from "primevue/blockui";
import { refDebounced } from "@vueuse/core";

const visible = ref(false);
const searchText = ref("");
const debounced = refDebounced(searchText, 500);
const blocked = ref(false);

watch(debounced, (newVal, oldVal) => {
  onSubmit();
});

const results = ref([]);
const onSubmit = () => {
  blocked.value = true;

  axios
    .get("search", {
      params: {
        search_text: searchText.value,
      },
    })
    .then((res) => (results.value = res.data.data.queries))
    .finally(() => (blocked.value = false));
};
</script>

<style lang="scss" scoped></style>
