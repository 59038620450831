<template>
  <div>
    <Dialog
      v-model:visible="visible"
      :header="modalHeader"
      modal
      :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <div class="grid" v-if="tabs">
        <div class="col-12 lg:col-4 md:col-4 sm:col-12">
          <Listbox
            v-model="selectedTab"
            :options="tabs"
            optionLabel="label"
            class="w-full md:w-14rem hidden md:block"
          >
            <template #option="slotProps">
              <div class="flex align-items-center gap-1">
                <i :class="slotProps.option.icon"></i>
                <div>{{ slotProps.option.label }}</div>
              </div>
            </template>
          </Listbox>

          <Dropdown
            v-model="selectedTab"
            :options="tabs"
            optionLabel="label"
            class="w-full block md:hidden"
          />
        </div>

        <div class="col-12 lg:col-8 md:col-8 sm:col-12">
          <component
            :is="NameEmailTab"
            v-if="selectedTab.name == 'name_email'"
            :settings="settings"
          ></component>
          <component
            :is="PasswordTab"
            v-if="selectedTab.name == 'password'"
            :settings="settings"
          ></component>
        </div>
      </div>

      <template #footer>
        <Button
          label="Chiudi"
          outlined
          severity="secondary"
          @click="visible = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { defineAsyncComponent, onMounted, ref, computed } from "vue";
import Dialog from "primevue/dialog";
import Listbox from "primevue/listbox";
import { useEventBus } from "@vueuse/core";
import { useAppStore } from "@/stores/app";
import Button from "primevue/button";
import NameEmailTab from "./NameEmailTab.vue";
import PasswordTab from "./PasswordTab.vue";
import Dropdown from "primevue/dropdown";

const appStore = useAppStore();

const visible = ref(false);
const tabs = ref([]);
const selectedTab = ref(null);
const settings = ref(null);

const getData = () => {
  axios({
    baseURL: appStore.config.url,
    url: `/api/v1/modules/shared/settings`,
  }).then((res) => {
    settings.value = res.data.data;
    tabs.value = res.data.extra["tabs"];
    selectedTab.value = tabs.value[0];
  });
};

const modalHeader = computed(() => {
  return "Impostazioni - " + (selectedTab.value ? selectedTab.value.label : "");
});

const openSettingsModal = useEventBus("omurai::shared::open_settings_modal");
onMounted(() => {
  getData();

  openSettingsModal.on(() => (visible.value = true));
});
</script>

<style lang="scss" scoped></style>
