<template>
  <div v-if="appStore.formErrors && appStore.formErrors[props.index]">
    <Message severity="error" :closable="false" class="m-0 my-1">{{
      appStore.formErrors[props.index][0]
    }}</Message>
  </div>
</template>

<script setup>
import Message from "primevue/message";
import { useAppStore } from "@/stores/app";
import { storeToRefs } from "pinia";

const props = defineProps({
  index: {
    type: String,
    required: true,
  },
});

const appStore = useAppStore();
</script>

<style lang="scss" scoped></style>
