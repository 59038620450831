<template>
  <div>
    <div class="field">
      <label for="firstname1">Password Attuale:</label>
      <Password
        v-model="form.current_password"
        class="w-full"
        toggleMask
        :feedback="false"
      />
      <FormsError index="current_password" />
    </div>

    <div class="field">
      <label for="lastname1">Nuova Password:</label>
      <Password v-model="form.password" class="w-full" toggleMask />
      <FormsError index="password" />
    </div>

    <div class="field">
      <label for="lastname1">Conferma Password:</label>
      <Password
        v-model="form.confirmation_password"
        class="w-full"
        toggleMask
      />
      <FormsError index="confirmation_password" />
    </div>

    <Button
      label="Salva"
      severity="success"
      @click="onSubmit"
      :loading="appStore.isLoading"
    />
  </div>
</template>

<script setup>
import { useAppStore } from "@/stores/app";
import Button from "primevue/button";
import Password from "primevue/password";
import FormsError from "../Forms/FormsError.vue";

const props = defineProps({
  settings: {
    type: Object,
    required: true,
  },
});

const appStore = useAppStore();
const form = {
  current_password: "",
  password: "",
  confirmation_password: "",
};
const onSubmit = () => {
  appStore.isLoading = true;

  axios({
    url: `${appStore.config.url}/user/password`,
    method: "PUT",
    data: form,
  });
};
</script>

<style lang="scss" scoped></style>
