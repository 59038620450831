import { createApp, inject, provide } from "vue/dist/vue.esm-bundler.js";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import axios from "axios";
import mitt from "mitt";
import { DateTime } from "luxon";
import Tooltip from "primevue/tooltip";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Aura from "@primevue/themes/aura";
import "/node_modules/primeflex/primeflex.css";
import "primeicons/primeicons.css";
import OmuraNavBar from "@SHARED/assets/js/components/OmuraNavBar.vue";
import FocusTrap from "primevue/focustrap";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

window.axios = axios;

/* import * as Sentry from "@sentry/vue";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  integrations: [
    Sentry.feedbackIntegration({
      colorScheme: "system",
      isNameRequired: true,
      isEmailRequired: true,
    }),
  ],
}); */

const pinia = createPinia();
const app = createApp();
app.use(pinia);
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      prefix: "",
      darkModeSelector: ".app-dark",
    },
  },
});

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["X-CSRF-TOKEN"] = AppConfig.csrf_token;
window.axios.defaults.baseURL =
  (AppConfig.module && AppConfig.module.api_url) ?? AppConfig.url;

//inject('axios', axios);

app.provide("emitter", mitt());
app.provide("DateTime", DateTime);

app.directive("tooltip", Tooltip);
app.directive("focustrap", FocusTrap);
app.component("toast", Toast);
app.component("confirm-dialog", ConfirmDialog);
app.component("omura-nav-bar", OmuraNavBar);
app.use(ToastService);
app.use(ConfirmationService);
app.use(LoadingPlugin);

export default app;
