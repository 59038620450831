<template>
  <div style="min-height: 100px">
    <div v-for="(row, key) in props.results">
      <h3 v-html="labels[key]"></h3>

      <div
        v-for="result in props.results[key]"
        class="border-1 border-gray-300 mb-2 p-1 cursor-pointer border-round hover:bg-blue-100"
        @click="onClick(result)"
        v-if="props.results[key].length > 0"
      >
        {{ result.label }}
        <h6 v-if="key != 'projects'" class="text-gray-400 m-0">
          {{ result.project.anagrafica.descrizione }} /
          {{ result.project.prm_nome }}
        </h6>
      </div>

      <div v-else>Non ci sono risultati</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  results: {
    type: Object,
  },
});

const labels = ref({
  projects: "Progetti",
  tasks: "Attività",
  documents: "Documenti",
});

const onClick = (result) => {
  if (!result.url) return false;

  window.location = result.url;
};
</script>

<style lang="scss" scoped></style>
