<template>
  <div>
    <div class="field">
      <label for="firstname1">Nome:</label>
      <InputText v-model="props.settings.user.name" class="w-full" />
      <FormsError index="name" />
    </div>

    <div class="field">
      <label for="lastname1">Email:</label>
      <InputText v-model="props.settings.user.email" class="w-full" />
      <FormsError index="email" />
    </div>

    <Button
      label="Salva"
      severity="success"
      @click="onSubmit"
      :loading="appStore.isLoading"
    />
  </div>
</template>

<script setup>
import { useAppStore } from "@/stores/app";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import FormsError from "../Forms/FormsError.vue";

const props = defineProps({
  settings: {
    type: Object,
    required: true,
  },
});

const appStore = useAppStore();

const onSubmit = () => {
  appStore.isLoading = true;

  const data = {
    name: props.settings.user.name,
    email: props.settings.user.email,
  };

  axios({
    url: `${appStore.config.url}/user/profile-information`,
    method: "PUT",
    data,
  });
};
</script>

<style lang="scss" scoped></style>
